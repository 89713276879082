@import "src/assets/styles/colors";

$b-size: 200%;
.LoadingTopBar{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 6px;
  z-index: 300;
  background: linear-gradient(80deg, $main-highlight 0%, white 50%, $main-highlight 100% );
  animation: move-back 4s infinite;
  background-size: $b-size 100%;
}

@keyframes move-back {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: $b-size 0;
  }
  100% {
    background-position: 0 0;
  }
}
