@font-face {
  font-family: "Lato-regular";
  src: url("./Lato/Lato-Regular.ttf");
}
@font-face {
  font-family: "Lato-bold";
  src: url("./Lato/Lato-Bold.ttf");
}
@font-face {
  font-family: "Lato-black";
  src: url("./Lato/Lato-Black.ttf");
}
