@import "mixins";
@import "colors";
@import "sizes";
@import "../fonts/fonts";
.Splash{
  text-align: center;

  .LoadingIcon{
    width: 85px;
    margin: auto;
  }
}
.splash-logo{
  width: 400px;
  max-width: 42%;
  display: block;
  margin: 100px auto 0;
}
html,body{
  font-family: $main-font;
  font-size: $font-size;
  color: $text-color;
}

b, strong{
  font-family: $bold-font;
  font-weight: 600;
}

.App{
  .content-container{
    padding: $main-padding-top 8%;
    position: relative;
  }
  .page-title{
    font-family: $bold-font;
    font-size: 1.6em;
  }
  .page-subtitle{
    font-size: 1.2em;
    color: $muted-color;
    margin-bottom: 1.2em;
  }
}

.center{
  text-align: center;
}

@mixin reset-btn{
  border: none;
  background: none
}
button.reset{
  @include reset-btn;
}

button.button{
  background-color: $main-highlight;
  border: none;
  border-radius: $button-border-radius;
  color: white;
  padding: .5em;
  &:disabled{
    opacity: 0.7;
  }
  &.danger{
    color: white;
    background-color: $error-red;
  }
  &.warning{
    color: white;
    background-color: $warning-1;
  }
}

.opm-separator{
  height: 3px;
  background-color: $main-highlight;
  border: 0;
  width: 110px;
  margin: 1em 0;
  &.center{
    margin: 1em auto;
  }
}

.input{
  display: block;
  background-color: $light-background;
  border-radius: $main-border-radius;
  border: 1px solid $dark-border;
  padding: 0.8em;
  margin: 1.4em 0;
  width: 100%;
  &::placeholder {
    color: $secondary-text-color;
  }
  &:-ms-input-placeholder {
    color: $secondary-text-color;
  }
  &.slim{
    padding: 0.4em;
    margin: 1em 0;
  }
}
button.link-btn{
  background: none;
  border: none;
  color: $main-highlight;
}

.opm-react-select{
  .opm-react-select__control{
    border-radius: $main-border-radius;
  }
  .opm-react-select__control--is-focused, .opm-react-select__control--is-focused:hover{
    box-shadow: 0 0 0 1px $main-highlight;
    border-color: $main-highlight;
  }
  .opm-react-select__indicator-separator{
    margin: 0;
  }
  .opm-react-select__option{
    cursor: pointer;
    &:hover, &.opm-react-select__option--is-focused{
      background-color: #00837320;
    }
    &.opm-react-select__option--is-selected{
      background-color: $main-highlight;
    }
  }
  &.form-react-select{
    .opm-react-select__control{
      background-color: $light-background;
      border: 1px solid $dark-border;
    }
  }
}

.DateRangePicker {
  .DateRangePickerInput{
    border-radius: $main-border-radius;
    .DateInput{
      width: 8em;
      background-color: transparent;
    }
    .DateInput_input {
      font-size: $font-size;
      padding: 0.32em;
      background-color: transparent;
      text-align: center;
    }
  }
  .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover{
    background-color: $main-highlight;
  }
  .CalendarDay__selected_span{
    background-color: $dim-highlight;
    color: $text-color;
  }
  .DateRangePickerInput_arrow{
    transform-origin: center;
    transform: scale(0.6);
  }
  .DateRangePickerInput__withBorder{
    border: 1px solid $light-border;
  }
}

.date-picker-container {

  h5 {
    font-weight: 600;
  }

  .date-picker {
    display: flex;
    position: relative;
    gap: 10px;
  }
}

.date-picker-wrapper {
  align-items: center;
  width: 50%; // Set width to 50% for side-by-side layout

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container {
    input {
      width: 100%;
      padding: 10px;
      border: 1px solid #444; // Darker border for contrast
      border-radius: 4px;
      font-size: 14px;
      transition: border-color 0.3s ease;

      &:focus {
        outline: none;
        border-color: #007bff;
        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
      }
    }
  }

  .delete-date-btn {
    width: 100%;
    background: red;
    color: white;
    border: none;
    font-weight: bold;
    cursor: pointer;
    padding: 0 5px;
    font-size: 16px;

    &:hover {
      color: lighten(#dc3545, 10%); // Lighten on hover for dark theme
    }

    &:focus {
      outline: none;
    }
  }
}

// Custom styles for the date picker popover
.react-datepicker {
  font-family: Arial, sans-serif;

  .react-datepicker__header {
  }



  .react-datepicker__day--selected {
    background-color: #007bff;

    &:hover {
      background-color: lighten(#007bff, 10%); // Lighten on hover for dark theme
    }
  }

  .react-datepicker__time-container {

    .react-datepicker__time-box {
      width: 100px;
    }
  }


  .react-datepicker__time-container {
    width: 70px; // Adjust this width as needed

    .react-datepicker__time {
    }

    .react-datepicker__time-box {
      width: 100%;
      overflow-x: hidden;
    }

    .react-datepicker__time-list {
      height: 200px !important; // Adjust height as needed

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
      }
    }

    .react-datepicker__time-list-item {
      padding: 5px 10px;


      &--selected {
        background-color: #007bff !important;
      }
    }
  }
}

// Responsive adjustments
@media (max-width: 768px) {
  .date-picker-container {
    .date-picker {
      flex-direction: column;
    }
  }

  .date-picker-wrapper {
    width: 100%;
  }
}


.SingleDatePicker{
  display: block;
  .SingleDatePickerInput{
    border-radius: $secondary-text-color;
    border: 1px solid $dark-border;
    display: block;
    background-color: $light-background;


    .DateInput{
      width: initial;
      background-color: transparent;
      display: block;
    }
    .DateInput_input {
      display: block;
      font-size: $font-size;
      padding: 0.32em;
      background-color: transparent;
    }
  }
  .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover{
    background-color: $main-highlight;
  }
  .CalendarDay__selected_span{
    background-color: $dim-highlight;
    color: $text-color;
  }
  .DateRangePickerInput_arrow{
    transform-origin: center;
    transform: scale(0.6);
  }
  .DateRangePickerInput__withBorder{
    border: 1px solid $light-border;
  }
}

.push-effect{
  @include push-effect();
}

.error-color{
  color: $error-red !important;
}

.success-color{
  color: $success-green !important;
}

.react-switch{
  vertical-align: middle;
}

.button.success{
  background-color: $success-green;
}

.muted{
  color: $muted-color;
}

.gap-1 {
  gap: 1em;
}

.gap-3 {
  gap: 1.5em;
}