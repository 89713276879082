
$text-color: black;
$main-highlight: #0197f6;
$dim-highlight: #b2e0fc;
$secondary-text-color: #727272;
$success-green: #008373;

$dark-border: $secondary-text-color;
$light-border: hsl(0,0%,80%);

$muted-color: #bbbcbf;
$error-red: #ff5c5c;
$light-red: #ffb5b5;

$light-background: #f6f6f6;

$warning-1: #ffb103;
$warning-2: #ff7303;
