@import "src/assets/styles/colors";

.TideToaster{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  .message-bar{
    width: 100%;
    text-align: center;
    padding: 10px;
    overflow: hidden;
    animation: toasterFullWidth 0.5s;
    margin: auto;
    &.error{
      background: $error-red;
      color: white;
    }
    &.warning{
      background: #fc4a1a;
      color: white;
    }
    &.info{
      background: linear-gradient(45deg, #2f60dc, #2e207c);
      color: white;
    }
    &.success {
      background: linear-gradient(45deg, #3cb305 30%, #016b01);
      color: white;
    }
  }
}
@keyframes toasterFullWidth {
  from {
    width: 0;
  }
  to{
    width: 100%;
  }
}
